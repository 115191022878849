import { http } from '@/http/axios.js'
import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js'
import { Message } from 'element-ui'
// 售后列表
export function getAfterSaleListAPI(params) {
  return http({
    url: '/admin/afterSale/order/page',
    method: 'get',
    params
  })
}
// 跟进记录列表
export function getAfterSaleRemarkListAPI(params) {
  return http({
    url: '/admin/afterSale/trace/page',
    method: 'get',
    params
  })
}
// 添加跟进记录
export function addAfterSaleRemarkAPI(data) {
  return http({
    url: '/admin/afterSale/trace/add',
    method: 'post',
    data
  })
}
//
export function getAfterSaleMemberStaticAPI(memberId) {
  return http({
    url: '/admin/afterSale/member/stat',
    method: 'get',
    params: { memberId }
  })
}
// 统计
export function getReviewOrderStaticAPI() {
  return http({
    url: '/admin/afterSale/order/status/stat',
    method: 'get'
  })
}
// 领单
export function getAfterSaleDrawAPI(orderNo) {
  return http({
    url: '/admin/afterSale/order/draw',
    method: 'post',
    data: { orderNo }
  })
}
// 放弃领单
export function handleAfterSaleAbandonAPI(orderNo) {
  return http({
    url: '/admin/afterSale/order/abandon',
    method: 'post',
    data: { orderNo }
  })
}
// 订单账单列表
export function getOrderBillListAPI(orderNo) {
  return http({
    url: '/admin/order/bill/list',
    method: 'get',
    params: { orderNo }
  })
}
// 订单详情
export function getOrderDetailAPI(orderNo) {
  return http({
    url: '/admin/review/order/detail',
    method: 'get',
    params: { orderNo }
  })
}
// 下载合同
export function downloadOrderContractAPI(orderNo) {
  return http({
    url: '/admin/order/contract/download',
    method: 'post',
    data: { orderNo }
  })
}
export function downloadFileByUrl(url) {
  showScreenLoading()
  // console.log(url);
  let link = document.createElement('a')
  //创建一个a标签
  link.style.display = 'none'
  //将a标签隐藏
  link.href = url
  //给a标签添加下载链接  "域名+接口"  safe是一个动态的域名  后面的接口替换成你自己的下载接口
  link.setAttribute('download', name)
  // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
  document.body.appendChild(link)
  //将上面创建的a标签加入到body的尾部
  link.click()
  //执行a标签
  hideScreenLoading()
}
// 三方
export function getOrderRiskAPI(memberId) {
  return http({
    url: '/admin/member/openRisk/data',
    method: 'get',
    params: { memberId }
  })
}

export function downFile(url, name) {
  getBlob(url).then(blob => {
    saveAs(blob, name)
  })
}
function getBlob(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status == 200) {
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}
function saveAs(blob, name) {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  link.click()
}
// 合同详情
export function getContractDetailAPI(orderNo) {
  return http({
    url: '/admin/order/contract/detail',
    method: 'get',
    params: { orderNo }
  })
}
// 下载文件2
export function downloadPDF(pdfUrl, name) {
  showScreenLoading()
  axios({
    url: pdfUrl,
    method: 'GET',
    responseType: 'blob' // 必须指定为blob类型才能下载
  }).then(
    res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      hideScreenLoading()
    },
    reason => {
      hideScreenLoading()
      Message.error('下载失败！')
    }
  )
}